@import 'font.scss';
@import '_variables.scss';

body{overflow-y: auto;margin: 0;font-size: 16px;font-family: 'Pretendard', sans-serif;font-weight: 500;word-break: break-all;letter-spacing: -0.5px;}
body.en{word-break: keep-all;}
h1, h2, h3, h4, h5, h6, p{margin: 0;}
b, strong{margin: 0;font-weight: bold;}
ol, ul, li, dl, dt, dd{margin:0; padding:0;}
li{list-style: none;}
a{transition: color 0.3s;}
a, a:hover, a:focus{text-decoration: none;}
a[href^=tel] {text-decoration:inherit;color: inherit;}
button{border:none;background: transparent;margin:0;padding: 0;cursor: pointer;color:#000;font-family: 'Pretendard', sans-serif;}
table{border-collapse: collapse;border-spacing: 0;}
th, td{padding:0;}
textarea, input{border:none;padding: 0;box-shadow: none;outline: none;-webkit-appearance: none;box-sizing: border-box;font-family: 'Pretendard', sans-serif;border-radius: 0;}
img{display: block;max-width: 100%;width: 100%;}
.hide{position: absolute;left: 0;top:0;width: 0;height: 0;opacity: 0;}
.container{max-width: 1384px;margin:0 auto;padding: 0 16px;}
.btn{display: inline-block;font-weight: 700;box-sizing: border-box;color:$Black;word-break: keep-all;}
.btn.icon{display: flex;justify-content: space-between;align-items: center;}
.btn.fill{color:#fff;background: #333;}
.btn.red{background: $Red;color:#fff;}
.btn.yello{background: $Yello;}

.fade:not(.imgWrap){position: relative;top:30px;opacity: 0;transition: all 0.7s ease;}
.fade.in:not(.imgWrap){top:0;opacity: 1;}
.fade.delay1{transition-delay: 0.2s;}
.fade.delay2{transition-delay: 0.4s;}
.fade.delay3{transition-delay: 0.6s;}
.fade.imgWrap{overflow: hidden;position: relative;}
.fade.imgWrap .wall{position: absolute;top:0;left: 0;right: 0;bottom:0;background: #fff;z-index: 1;transition: all 0.6s ease 0.3s;}
.fade.imgWrap.in .wall{transform: translateY(-100%);}

.table{margin-top:42px;border-top: 2px solid #333;@include fontStyle(14px, 400, #333);}
.thead{display: table;width: 100%;}
.thead .th{display: table-cell;padding:11px 32px;border-bottom: 1px solid #878791;font-weight: 400;}
.thead .th:first-child{width:32%;}
.thead .th + .th{flex:1;}
.tbody{display: table;width: 100%;}
.tbody .tr{display: table-row;}
.tbody .th,
.tbody .td{display: table-cell;font-size: 16px}
.tbody .th{width:32%;font-weight: 700;vertical-align: middle;padding:18px 32px;border-bottom: 1px solid #E6E6EB;}
.tbody .td{flex:1;}
.tbody .td p{padding:18px 32px;border-bottom: 1px solid #E6E6EB;}

.flexBox{display: flex;align-items: center;gap:32px;}
.flexBox > *{flex:1;}

.moreBox{position: relative;display: flex;align-items: center;justify-content: space-between;gap: 20px;height: 158px;margin-top:183px;padding:0 120px;border:1px solid #B4B4BE;background: url('../images/more-img.png') no-repeat center / cover;margin-bottom: 160px;}
.moreBox h3{position: absolute;top:-17px;left: 117px;padding:0 16px;background: #fff;@include fontStyle(32px, 700, $Black);}
.moreBox p{@include fontStyle(18px, 400, #555, 27px);}
.moreBox .btn{@include btn(200px, 56px, 16px, 24px);flex:none;}
.en .moreBox .btn{width: fit-content;gap:24px;}
.moreBox .btn img{width:24px;}

@media (min-width: $Mobile-width) {
  .mb{display: none !important;}
}
@media (min-width: $Tablet-width) {
  .tb,
  .tb-mb{display: none !important;}
}

@media (max-width: $Tablet-width) {
  .flexBox{flex-direction: column;align-items: normal;gap: 64px;}
  .moreBox{top:-14px;margin-top:138px;padding:0 40px;background-image: url('../images/more-img-m.png');margin-bottom: 120px;}
  .moreBox h3{top:-17px;left: 24px;font-size: 24px;}
  .pc{display: none !important;}
  .tb{display: block !important;}
  .tb-mb{display: block !important;}
}
@media (max-width: $Mobile-width) {
  .pc-tb{display: none !important;}
  .tb{display: none !important;}
  .mb{display: block !important;}
  .mBlock{flex-direction: column;}
  .fade.imgWrap .wall{transition-delay: 0s;}
  .table{margin-top:24px;border-top-width: 1px;}
  .thead{display: none;}
  .tbody,
  .tbody .tr,
  .tbody .th,
  .tbody .td{display: block;}
  .tbody .tr{padding:0 8px 16px;border-bottom: 1px solid #E6E6EB;}
  .tbody .th{width:100%;padding:16px 0;border-bottom: none;}
  .tbody .td p{display: flex;align-items: flex-start;gap:6px;padding:0;border-bottom: none;font-size: 14px;}
  .tbody .td p:before{content:'';width:4px;height: 4px;margin-top: 6px;background: #646469;border-radius: 50%;}
  .tbody .td p + p{margin-top:4px;}

  .flexBox{gap:40px;}

  .moreBox{position: static;flex-direction: column;align-items: flex-start;height: auto;gap:0;margin-top:80px;padding:24px;border:1px solid #B4B4BE;background:none;margin-bottom: 80px;}
  .moreBox h3{position: static;padding:0;font-size: 18px;line-height: 27px;}
  .moreBox p{margin-top: 12px;font-size: 14px;line-height: 21px;}
  .moreBox p br{display: none;}
  .moreBox .btn{width: 100%;margin-top: 32px;}
  .en .moreBox .btn{width: 100%;gap:0;line-height: 20px;}
}

// layout
#header{position: fixed;top:0;left: 0;right: 0;height: 80px;z-index: 9;transition: all 0.3s;background: #fff;}
#header .btnToggle{display: none;}
#header .container{display: flex;justify-content: space-between;align-items: center;height: 100%;}
#header h1 a{@include icon (108px, 24px, 'ten-b.png', block);}
#header .btnClose{display: none;}
#header .menuWrap{display: flex;align-items: center;gap:120px;}
#header nav{display: flex;align-items: center;gap:80px;}
#header nav .depth1{position: relative;@include fontStyle(18px, 600, #333, 48px);}
#header nav .depth1:after{content:'';position: absolute;bottom:0;left: 50%;width:0;height: 3px;background: $Black;transition: 0.3s all;}
#header nav .depth1.current{font-weight: 700;}
#header nav .depth1.current:after,
#header nav .depth1:hover:after{width:100%;left: 0;}
#header nav .depthWrap{position: relative;}
#header nav .depthWrap .depth1{display: block}
#header nav .depth2{display: none;position: absolute;top:48px;align-items: center;gap:32px;padding-top:18px;white-space: nowrap;}
#header nav .depth2 a{@include fontStyle(16px, 400, #646469);}
#header nav .depth2 a:hover{color:$Black;}
#header nav .depth2 a.current{font-weight: 700;}
#header.reachTop{background: transparent;height: 112px;}
#header.down{top:-112px;}
#header.hover{background: #fff;}
#header.depth2on{padding-bottom: 40px;}
#header.depth2on nav .depthWrap.on .depth1:after{width:100%;left: 0;}
#header.depth2on nav .depthWrap.on .depth2{display: flex;}
#header .langBtn{display: flex;align-items: center;gap:12px;}
#header .langBtn button{@include fontStyle(16px, 600, #333);opacity: 0.3;}
#header .langBtn button.active{opacity: 1;}
#header .langBtn span{color:#B4B4BE;}

footer{padding:64px 0;border-top:1px solid #E6E6EB;}
footer .container{display: flex;justify-content: space-between;}
footer h2{@include fontStyle(24px, 700, $Black);}
footer h2 span{@include icon (88px, 19px, 'ten-r.png');display: block;margin-bottom: 12px;}
footer h2:after{content:'';display: inline-block;width:8px;height: 8px;background: $Red;border-radius: 50%;margin-left: 6px;}
footer address{margin-top: 40px;}
footer address p{@include fontStyle(14px, 400, #646469, 21px);font-style: normal;}
footer address p + p{margin-top: 8px;}
footer address p span{margin:0 12px;color:#D5D5DA;}
footer address .copyright{margin-top:24px;@include fontStyle(13px, 400, #B4B4BE);}
footer .snsWrap{display: flex;align-items: center;gap:25px;}
footer .snsWrap img{display: block;width:40px;}

@media (max-width: $Tablet-width) {
  #header,
  #header.reachTop{height: 72px;}
  #header .topArea{position: relative;flex:1;display: flex;justify-content: space-between;align-items: center;z-index: 1;}
  #header .btnToggle{@include icon (40px, 40px, 'menu-open.png');}
  #header .menuWrap{overflow: auto;transform: translateY(-120%);position: fixed;right: 0;left: 0;top: 72px;bottom:0;z-index: 9;background: #fff;flex-direction: column;justify-content: space-between;align-items: flex-start;padding:80px;box-sizing: border-box;transition: all 0.3s;z-index: 0;gap:56px;}
  #header nav{flex-direction: column;gap:32px;align-items: flex-start;}
  #header nav .depth1{@include fontStyle(32px, 700, $Black);}
  #header nav .depth1.current:after,
  #header nav .depth1:hover:after{display: none;}
  #header nav .depth2{display: block;position: static;padding: 24px 0;}
  #header nav .depth2 a{@include fontStyle(18px, 400, #878791);display: block;}
  #header nav .depth2 a + a{margin-top:24px;}
  #header.down{top:-72px;}
  #header.open{background: #fff;}
  #header.open .menuWrap{transform: translateY(0);}
  #header.open .topArea{background: #fff;}
  #header.open .btnToggle{background-image: url('../images/menu-close.png');}
  #header .langBtn button{font-size: 18px;}
}
@media (max-width: $Mobile-width) {
  #header,
  #header.reachTop{height: 56px;}
  #header.down{top:-56px;}
  #header.open .menuWrap{top: 56px;}
  #header .langBtn button{font-size: 16px;}
  #header .btnToggle{width: 32px;height: 32px;}
  #header .menuWrap{overflow: auto;padding:40px;}
  #header h1 a{width: 72px;height: 18px;}
  #header nav{gap:24px;}
  #header nav .depth1{font-size: 24px;}
  #header nav .depth2{padding: 16px 0;}
  #header nav .depth2 a{font-size: 16px;}
  #header nav .depth2 a + a{margin-top:16px;}

  footer{padding:40px 0;}
  footer .container{gap:24px;}
  footer .snsWrap{gap:10px;}
  footer .snsWrap img{width:32px;}
}

// home
.home .topArea{position:relative;background: #EEF8FF url('../images/home-banner.png') no-repeat center / auto 100%;}
.home .imgWrap{position: absolute;top:0;right: 0;bottom:0;left: 0;background: #EEF8FF;opacity: 1;transition: all 0.6s ease 0.3s;}
.home .imgWrap.in{transform: translateY(-100%);}
.home .topArea .container{position: relative;display: flex;flex-direction: column;justify-content: flex-end;height: 652px;z-index: 1;}
.home .topArea h2{@include fontStyle(48px, 200, $Black, 72px);}
.home .topArea h2 b{font-weight: 800}
.home .topArea p{margin-top: 40px;@include fontStyle(24px, 400, #333, 42px);}
.home .topArea .btnWrap{display: flex;margin-top: 80px;}
.home .topArea .btnWrap .btn{@include btn(175px, 72px, 18px, 24px);line-height: 20px;word-break: keep-all;}
.home .topArea .btnWrap .btn img{width:24px;}
.home .section1{padding:160px 0;text-align: center;}
.home .section1 .box{display: flex;align-items: flex-end;gap:43px;justify-content: center;}
.home .section1 dt{@include fontStyle(18px, 300, $Black);}
.home .section1 dd{margin-top: 4px;@include fontStyle(80px, 800, $Black);}
.home .section1 span{margin-bottom: 25px;}
.home .section1 img{display: block;width:48px;}
.home .section1 p{margin-top: 64px;@include fontStyle(24px, 300, #333, 42px);}
.home .section1 p b{font-weight: 700;}
.home .section2{padding: 240px 0;background: #F7F7F7;}
.home .section2 h2{@include fontStyle(24px, 300, #333, 60px);text-align: center;}
.home .section2 h2 b{@include fontStyle(56px, 800, $Black);}
.home .section2 h3{margin-top:64px;@include fontStyle(32px, 700, $Black, 48px);}
.home .section2 p{@include fontStyle(18px, 400, #333, 32px);}
.home .section2 .logo{width:auto;height: 67px;}
.home .section2 .group1{padding-bottom: 160px;border-bottom: 1px solid #D5D5DA;}
.home .section2 .group1 img{max-width: 896px;margin:80px auto 0;}
.home .section2 .group1 p{margin-top: 24px;@include fontStyle(16px, 400, #646469, 19px);text-align: center;}
.home .section2 .group2{margin:160px 0;}
.home .section2 .group2 p{margin-top: 24px;}
.home .section2 .group2 div + div img{width: auto;height: 400px;}
.home .section2 .group2 .btn{margin-top: 64px;@include btn(fit-content, 56px, 16px);color:#333;gap:18px;}
.home .section2 .group2 .btn img{width: 24px;}
.home .section2 .group3 p{margin-top: 16px;}
.home .section2 .group3 .flexBox{margin-top: 80px;}
.home .section2 .group3 .box{display: flex;align-items: flex-end;justify-content: space-between;padding: 40px 48px 48px;background:#fff;}
.home .section2 .group3 .box p{@include fontStyle(16px, 400, #555, 28px);}
.home .section2 .group3 .box strong{display: block;margin-bottom: 24px;@include fontStyle(24px, 300, #333, 36px);}
.home .section2 .group3 .box strong b{font-weight: 700;}
.home .section2 .group3 .box a{position: relative;@include btn(140px, 56px, 16px);color:#333;}
.home .section2 .group3 .box a:after{content:'';position: absolute;bottom:0;left: 50%;width:0;height: 3px;background: $Black;transition: 0.3s all;}
.home .section2 .group3 .box a:hover:after{width:100%;left: 0;}
.home .section2 .group3 .box a img{width: 24px;}
.home .section3{padding: 160px 0;text-align: center;}
.home .section3 h2{@include fontStyle(32px, 300, $Black, 48px);}
.home .section3 h2 b{font-weight: 800;}
.home .section3 .iconWrap{margin: 104px -16px -16px;font-size: 0;}
.home .section3 .iconWrap li{display: inline-block;width: 25%;padding:16px;vertical-align: middle;box-sizing: border-box;}
.home .section3 .iconWrap li > div{display: flex;flex-direction: column;align-items: center;justify-content: center;border:1px solid #D5D5DA;aspect-ratio: 1 / 1;border-radius: 50%;@include fontStyle(16px, 400, #7F7F7F);}
.home .section3 .iconWrap li img{width:auto;height: 80px;}
.home .section3 .iconWrap li b{display: block;margin:24px 0 8px;@include fontStyle(24px, 700, #333);}
.home .section4{padding: 80px 0;background: #F7F7F7;}
.home .section4 h2{display: flex;align-items: center;justify-content: center;gap:8px;@include fontStyle(24px, 400, $Black);}
.home .section4 h2 b{font-weight: 700;}
.home .section4 h2 img{width:18px;}
.home .section4 .logo{width:200px;margin: 40px auto 0;}

@media (max-width: $Tablet-width) {
  .home .topArea{padding-top:192px;background: #EEF8FF;}
  .home .topArea .container{height: auto;}
  .home .topArea h2{font-size: 40px;line-height: 60px;}
  .home .topArea p{margin-top: 24px;}
  .home .section1{padding:120px 0;}
  .home .section1 .box{gap:32px;}
  .home .section1 dd{font-size: 64px;}
  .home .section1 span{margin-bottom: 20px;}
  .home .section1 img{width:32px;}
  .home .section1 p{margin-top: 40px;}
  .home .section2{padding: 160px 0;}
  .home .section2 h3{margin-top:40px;}
  .home .section2 h3 br{display: none;}
  .home .section2 .group2 p{margin-top: 16px;}
  .home .section2 .group2 p br{display: none;}
  .home .section2 .group2 div + div img{width: 100%;height: auto;}
  .home .section2 .group2 .btn{margin-top: 40px;}
  .home .section2 .group2 .btn img{height: 24px;}
  .home .section2 .group3 .flexBox{margin-top: 64px;gap:32px;}
  .home .section3{padding: 120px 0;}
  .home .section3 h2 br{display: none;}
  .home .section3 .iconWrap{margin-top: 80px;}
  .home .section3 .iconWrap li{width: 50%;}
}
@media (max-width: $Mobile-width) {
  .home .topArea{padding-top:138px;}
  .home .topArea h2{font-size: 24px;line-height: 38px;}
  .home .topArea p{font-size: 18px;line-height: 32px;}
  .home .topArea .btnWrap .btn{width:50%;}
  .home .section1{padding:80px 0;}
  .home .section1 .box{flex-direction: column;align-items: center;gap:24px;}
  .home .section1 dt{font-size: 16px;}
  .home .section1 dd{margin-top: 0;font-size: 40px;}
  .home .section1 span{margin-bottom: 0;}
  .home .section1 img{width:16px;}
  .home .section1 p{font-size: 18px;line-height: 32px;}
  .home .section2{padding: 80px 0;}
  .home .section2 h2{font-size: 24px;line-height: 42px;text-align: left;}
  .home .section2 h2 b{font-size: inherit;}
  .home .section2 h3{margin-top:24px;font-size: 24px;line-height: 36px}
  .home .section2 p{font-size: 16px;line-height: 28px;}
  .home .section2 .logo{height: 48px;}
  .home .section2 .group1{padding-bottom: 120px;}
  .home .section2 .group1 img{margin-top: 40px;}
  .home .section2 .group1 p{font-size: 14px;line-height: 17px;}
  .home .section2 .group2{margin:120px 0;}
  .home .section2 .group2 div + div img{height: auto;}
  .home .section2 .group2 .btn{margin-top: 64px;@include btn(140px, 56px, 16px);color:#333;}
  .home .section2 .group2 .btn img{width: 24px;}
  .home .section2 .group3 .flexBox{margin-top: 80px;}
  .home .section2 .group3 .box{flex-direction: column;align-items: flex-start;padding: 24px;}
  .home .section2 .group3 .box strong{font-size: 18px;line-height: 27px;}
  .home .section2 .group3 .box a{margin-top:40px;@include btn(114px, 28px, 14px);}
  .home .section2 .group3 .box a img{width: 18px;}
  .home .section3{padding: 80px 0;}
  .home .section3 h2{font-size: 24px;line-height: 36px;}
  .home .section3 .iconWrap{margin-top:32px;}
  .home .section3 .iconWrap li{padding: 8px;}
  .home .section3 .iconWrap li > div{font-size: 12px;}
  .home .section3 .iconWrap li b{margin:8px 0 4px;font-size: 16px;}
  .home .section3 .iconWrap li img{height:56px;}
  .home .section4{padding: 80px 0;background: #F7F7F7;}
  .home .section4 h2{flex-direction: column;}
  .home .section4 h2 img:first-child{align-self: flex-start;}
  .home .section4 h2 img:nth-child(3){align-self: flex-end;}
  .home .section4 .logo{width:200px;margin: 40px auto 0;}
}

@media (max-width: $Fold-width) {
  .home .section3 .iconWrap li img{width: 44px}
}

// about
.about .topArea{overflow: hidden;background: #F1F2F6;}
.about .topArea .container{position: relative;display: flex;justify-content: space-between;gap: 32px;}
.about .topArea .content{padding: 272px 0 120px;}
.about .topArea h2{@include fontStyle(72px, 800, $Black, 90px);}
.about .topArea .text{margin-top:64px;@include fontStyle(24px, 400, #333, 42px);}
.about .topArea .wrap{position: relative;flex: 1;display: flex;justify-content: flex-end;gap:32px;}
.about .topArea .bg{position: absolute;top:0;left: 0;right: 0;height: 160px;background: linear-gradient(180deg, #F1F2F6 20.83%, rgba(241, 242, 246, 0.82) 65.62%, rgba(241, 242, 246, 0) 100%);z-index: 1;}
.about .topArea .slick-list{overflow: visible;}
.about .topArea .slick-slider{position: absolute;top:0;bottom:0;width:260px;}
.about .topArea #forwardCard{right:292px;}
.about .topArea #reverseCard{right:0;}
.about .topArea li{width: 236px;padding: 32px 24px 24px;box-sizing: border-box;background: #fff;box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12), 2px 12px 20px rgba(0, 0, 0, 0.08);border-radius: 12px;}
.about .topArea #forwardCard li + li{margin-top: 32px;}
.about .topArea #reverseCard li + li{margin-bottom: 32px;}
.about .topArea li h3{margin-top:56px;@include fontStyle(18px, 700, $Black);}
.about .topArea li p{margin-top:8px;@include fontStyle(14px, 400, #555);}
// .about .topArea .forwardWrap{padding-bottom: calc(100vh * 0.7);}
// .about .topArea .forwardWrap .bg{position: sticky;}
// .about .topArea .forwardWrap ul{margin-top: -160px;}
// .about .topArea .reverseWrap{position:absolute; bottom:-90px;right:0;padding-top: calc(100vh * 0.7);transition: all 0.1s;}
// .about .topArea .acnorWrap{overflow: hidden;position: sticky;top:0;right: 0;width: 236px;height: 100vh;}
// .about .topArea .acnorWrap .bg{position: absolute;}
.about .section1{padding:120px 0;}
.about .section1 .flexBox{justify-content: space-between;padding: 120px 0;}
.about .section1 h3{margin-bottom: 48px;@include fontStyle(48px, 700, $Black, 72px);}
.about .section1 p{@include fontStyle(24px, 400, #333, 42px);}
.about .section1 p + p{margin-top: 32px;}
.about .section1 .text{max-width:558px;}
.about .section1 .img img{width:auto;max-height: 400px;}
.about .section1 .text + .img img{margin:0 0 0 auto;}
.about .section2{padding: 240px 0 320px;background: #F1F2F6;text-align: center;}
.about .section2 h2{@include fontStyle(48px, 700, $Black, 72px);}
.about .section2 p{margin-top:48px;@include fontStyle(24px, 400, #333, 42px);}
.about .section2 .box{display: flex;gap:64px;max-width: 1146px;margin:120px auto 0;}
.about .section2 .box li{flex:1;padding:100px 0;background: #fff;box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12), 2px 12px 20px rgba(0, 0, 0, 0.08);border-radius: 12px;text-align: center;@include fontStyle(18px, 400, #333);}
.about .section2 .box li img{width:auto;height: 156px;margin:0 auto 48px;}
.about .section2 .box li b{display: block;margin-top: 8px;font-weight: 700;font-size: 32px;}
.about .section3{padding:320px 0 360px;}
.about .section3 h2{@include fontStyle(72px, 700, $Black, 120px);}
.about .section3 h2 .io{display: flex;align-items: center;gap:48px;}
.about .section3 h2 .io b{font-size: 140px;}
.about .section3 h2 .bar{flex: 1;height: 2px;background: $Black;}
.about .section3 h2 .ai{display: block;}
.about .section3 p{@include fontStyle(24px, 400, #333, 42px);}
.about .section3 h3{@include fontStyle(32px, 700, $Black);}
.about .section3 .topContent{margin-bottom: 320px;}
.about .section3 .topContent p{margin-top:80px;}
.about .section3 .flexBox + .flexBox{margin-top: 160px;}
.about .section3 .flexBox p{margin-top: 32px;}
.about .section3 .flexBox img{max-width:448px;}
.about .section3 .flexBox > div + div img{max-width:176px;margin-bottom: 64px;;}
.about .section4{padding-bottom: 160px;text-align: center;}
.about .section4 h2{position: relative;display: flex;justify-content: center;margin-bottom: 280px;}
.about .section4 h2::before{content:'';position: absolute;left: 0;top:30px;right: 0;height: 1px;background: #333;}
.about .section4 h2 span{position: relative;z-index: 1;display: flex;align-items: flex-start;gap:24px;padding:0 120px;background: #fff;@include fontStyle(48px, 700, $Black);}
.about .section4 h2 img{width:27px;}
.about .section4 h2 br{display: none;}
.about .section4 h3{@include fontStyle(48px, 700, $Black);}
.about .section4 p{margin-top:48px;@include fontStyle(24px, 400, #333, 42px);}
.about .section4 .map{max-width: 1384px;width: 100%;height: 640px;margin:64px auto 0;}
.about .section4 .contact{display: flex;justify-content: space-between;align-items: flex-end;margin-top:64px;text-align: left;}
.about .section4 .contact h4{margin-bottom: 32px;@include fontStyle(24px, 800, #333);}
.about .section4 .contact .address > div{display: flex;gap:32px;align-items: flex-start;margin-top:16px;@include fontStyle(24px, 400, #333);}
.about .section4 .contact .dl{display: flex;align-items: flex-start;gap:12px;margin:0;}
.about .section4 .contact .dt{flex: none;font-weight: 800;}
.about .section4 .contact .btn{flex:none;min-width: 268px;@include btn(fit-content, 56px, 16px, 24px);gap:24px;}
.about .section4 .contact .btn img{width:24px;}

@media (max-width: $Tablet-width) {
  .about .topArea{padding-bottom: 70px;}
  .about .topArea .container{flex-direction: column;}
  .about .topArea .bg{display: none;}
  .about .topArea .wrap{flex-direction: column;width: 100%;}
  .about .topArea .content{padding:192px 0 0;}
  .about .topArea h2{font-size: 48px;line-height: 72px;text-align: center;}
  .about .topArea .text br{display: none;}
  .about .topArea #reverseCard{display: none;}
  .about .topArea #forwardCard{position: static;}
  .about .topArea #forwardCard li{width:260px !important;}
  .about .topArea #forwardCard li + li{margin: 0 0 0 32px;}
  // .about .topArea .forwardWrap{overflow: auto;width: calc(100vw - 16px);padding:0 0 30px 16px;margin:64px 0 0 -16px;box-sizing: border-box;}
  // .about .topArea .forwardWrap ul{display: flex;width:1576px;gap:32px;margin-top: 0;}
  // .about .topArea li + li{margin:0;}
  // .about .topArea .acnorWrap{display: none;}
  .about .section1{padding:40px 0;}
  .about .section1 .text{order:2;max-width:initial;}
  .about .section1 .img{order:1;}
  .about .section1 .img img,
  .about .section1 .text + .img img{margin:0 auto;}
  .about .section1 br{display: none;}
  .about .section2{padding: 160px 0;}
  .about .section2 .box{gap:32px;max-width: 1146px;margin-top:80px;}
  .about .section2 .box li{padding:48px 0;}
  .about .section2 .box li img{margin-bottom:32px;}
  .about .section3{padding:160px 0 240px;}
  .about .section3 h2{font-size: 48px;line-height: 80px;}
  .about .section3 h2 .io{gap:24px;}
  .about .section3 h2 .io b{font-size: 100px;}
  .about .section3 .topContent{margin-bottom: 240px;}
  .about .section4 h2{margin-bottom: 240px;}
  .about .section4 h2::before{top:15px;}
  .about .section4 h2 span{gap:18px;padding:0 40px;margin:0 20px;font-size: 32px;}
  .about .section4 h2 img{width:18px;}
  .about .section4 .contact{flex-direction: column;align-items: flex-start;gap:64px;}
}
@media (max-width: $Mobile-width) {
  .about .topArea{padding-bottom: 50px;}
  .about .topArea .content{padding-top:138px;}
  .about .topArea h2{font-size: 32px;line-height: 48px;}
  .about .topArea .text{margin-top:40px;font-size: 18px;line-height: 32px;}
  .about .topArea .forwardWrap{margin-top:40px;}
  .about .topArea .forwardWrap ul{width:1516px;gap:24px;}
  .about .topArea li h3{margin-top:40px;}
  .about .section1 .flexBox{padding: 80px 0;}
  .about .section1 h3{margin-bottom: 24px;font-size: 32px;line-height: 48px;}
  .about .section1 p{font-size: 18px;line-height: 32px;}
  .about .section1 p + p{margin-top: 24px;}
  .about .section2{padding: 120px 0;}
  .about .section2 h2{font-size: 32px;line-height: 48px;}
  .about .section2 p{margin-top:24px;font-size: 18px;line-height: 32px;}
  .about .section2 .box{flex-direction: column;gap:16px;margin-top: 40px;}
  .about .section3{padding:120px 0 160px;}
  .about .section3 h2{font-size: 32px;line-height: 48px;}
  .about .section3 h2 .io{gap:8px;}
  .about .section3 h2 .io b{font-size: 56px;}
  .about .section3 p{font-size: 18px;line-height: 32px;}
  .about .section3 h3{font-size: 24px;line-height: 36px;}
  .about .section3 .topContent{margin-bottom: 120px;}
  .about .section3 .topContent p{margin-top:40px;}
  .about .section3 .flexBox + .flexBox{margin-top: 80px;}
  .about .section3 .flexBox p{margin-top: 16px;}
  .about .section3 .flexBox > div + div img{margin-bottom: 40px;}
  .about .section4{padding-bottom: 160px;text-align: center;}
  .about .section4 h2{margin-bottom: 160px;}
  .about .section4 h2::before{top:52px;}
  .about .section4 h2 span{gap:0;padding:0 24px;font-size: 24px;line-height: 36px;}
  .about .section4 h2 img{display: none}
  .about .section4 h2 br{display: initial;}
  .about .section4 h3{font-size: 32px;}
  .about .section4 p{margin-top:24px;font-size: 18px;line-height: 32px;}
  .about .section4 .map{height: 320px;margin-top:40px;}
  .about .section4 .contact{margin-top:40px;}
  .about .section4 .contact h4{font-size: 18px;}
  .about .section4 .contact p{gap:32px;font-size: 18px;}
  .about .section4 .contact .address > div{flex-direction: column;gap:16px}
  .about .section4 .contact .btn{min-width: auto;width: 100%;line-height: 20px;}
}
@media (max-width: $Fold-width) {
  .about .topArea h2 br{display: none;}
  .about .section4 .contact .btn{gap:12px;}
  .about .section3 h2 .io{flex-direction: column;align-items: flex-start;}
}

// partner
.partner .topArea{padding: 243px 0 124px;}
.partner .topArea .container{display: flex;align-items: center;justify-content: space-between;gap: 40px;}
.partner .topArea h2{@include fontStyle(72px, 800, $Black);}
.partner .topArea .text1{margin-top:64px;@include fontStyle(24px, 700, $Black);}
.partner .topArea .text2{margin-top:24px;@include fontStyle(18px, 400, #333, 27px);}
.partner .topArea img{max-width:502px;}
.partner .section{padding:144px 0;background: #F1F2F6;}
.partner .box{display: flex;gap:128px;}
.partner .box + .box{margin-top: 128px;}
.partner .box h3{flex:none;display: flex;align-items: center;width:208px;height: 100px;@include fontStyle(24px, 700, #333, 36px);}
.partner .box ul{flex:1;margin:-28px -16px;font-size: 0;}
.partner .box li{display: inline-block;width:33.33%;padding:28px 16px;box-sizing: border-box;vertical-align: top;}
.partner .box li > div{padding:30px 0;background: #fff;box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.04);}
.partner .box li img{display: block;width: auto;height: 48px;margin:0 auto;}
.partner .box p{margin-top: 16px;@include fontStyle(16px, 400, #333);text-align: center;}

@media (max-width: $Mobile-width) {
  .partner .topArea{padding: 116px 0 80px;}
  .partner .topArea .container{flex-direction: column;}
  .partner .topArea h2{margin-top: 40px;font-size: 32px;}
  .partner .topArea .text1{margin-top:40px;font-size: 18px;}
  .partner .topArea .text2{margin-top:16px;font-size: 16px;line-height: 24px;}
  .partner .topArea img{order:1;max-width:276px;}
  .partner .topArea img.fade.in{transition-delay: 0s;}
  .partner .topArea .content{order:2;width: 100%;}
  .partner .section{padding:80px 0;}
  .partner .box{flex-direction: column;gap:0;}
  .partner .box + .box{margin-top: 64px;}
  .partner .box h3{width:100%;height: auto;font-size: 18px;line-height: 1;}
  .partner .box h3 br{display: none;}
  .partner .box ul{margin:24px 0 0;}
  .partner .box li{width:100%;padding:0;}
  .partner .box li + li{margin-top: 24px;}
  .partner .box li > div{padding:24px 0;}
  .partner .box li img{height: 32px;}
  .partner .box p{margin-top: 12px;font-size: 14px;}
}

// product
.product{background: no-repeat top center / 1920px auto;}
.product img{display: block;width:100%;}
.product .topArea{text-align: center;}
.product .topArea .logo{margin:0 auto 80px;}
.product .topArea h2{@include fontStyle(48px, 700, $Black, 72px);}
.product .topArea p{margin-top:48px;@include fontStyle(24px, 400, #333, 42px);}
.product section .text{@include fontStyle(18px, 400, #333, 32px);}
.product section h2{margin-bottom: 64px;}
.product section h2 img{width:auto;height: 64px;}
.product section h3{@include fontStyle(24px, 700, $Black, 36px);}
.product section h3 + p{margin-top:24px;}
.product .flexBox{border-bottom: 1px solid #D5D5DA;}
.product iframe{max-width: 912px;width:100%;margin-top:80px;aspect-ratio: 57 / 32;box-shadow: 0px 32px 32px rgba(0, 0, 0, 0.12);}
.product .group1 img{margin-top:40px;}
.product .group2{margin-top:160px}
.product .group3{padding:160px 0 120px;}
.product .group4{padding:120px 0 160px;}
.product .group5{padding:160px 0 0;text-align: center;}
.product .group5 .container{position: relative;z-index: 1;}
.product .group5 .pc{width:100%;height: 1020px;margin-top: -180px;z-index: 0;background: url('../images/product1-img5.png') no-repeat center bottom / 1920px auto;}
.product .group6{padding: 120px 0;text-align: center;}
.product .group9{padding: 160px 0 120px;}
.product .group10{padding: 120px 0;}
.product .group10 ul{display: flex;gap:32px;flex-wrap: wrap;}
.product .group10 li{width:calc(33.33% - 22px);background: #F1F2F6;border-radius: 16px;padding: 24px 12px;@include fontStyle(14px, 400, #333, 21px);text-align: center;box-sizing: border-box;}
.product .group10 li b{display: block;margin-bottom: 16px;@include fontStyle(16px, 700, $Black);}
.product .group11{padding: 120px 0;}
.product .group12{padding: 120px 0 160px;}
.product .group13{padding:160px 0 0;text-align: center;}
.product .group13 .container{position: relative;z-index: 1;}
.product .group13 .pc{width:100%;height: 1020px;margin-top:-180px;background: url('../images/product1-img10.png') no-repeat center bottom / 1920px auto;z-index: 0;}
.product .group15{padding:120px 0;background: #F1F2F6;}
.product .group15 .container{display: flex;align-items: center;justify-content: space-between;}
.product .group15 h3{font-size: 32px;}
.product .group15 .text{margin-top:64px;}
.product .group15 .email{margin-top:24px;@include fontStyle(16px, 500, #878791);}
.product .group15 .email span{margin-left: 12px;color:#333;}
.product .group15 img{max-width: 276px;}
.product .group17{margin-top: 120px;}
.product .group17 img{margin-top:40px;}
.product .group17 > div + div{margin-top:80px;}
.product .group17 .imgWrap{margin-top:40px;padding:64px 0;border:1px solid #D5D5DA;}
.product .group17 .imgWrap img{max-width: 748px;margin:0 auto;}
.product .group18{padding:120px 0;background: #F1F2F6;text-align: center;}
.product .group18 h3{margin-top:12px;@include fontStyle(32px, 700, $Black);}
.product .group18 p{@include fontStyle(16px, 500, #646469);}
.product .group18 .btn{@include btn(220px, 64px, initial);margin:64px auto 0;padding:0 20px 0 32px;background: #fff;border-radius: 8px;box-shadow: 0px 4px 6px rgba(135, 135, 145, 0.2);}
.product .group18 .btn .logo{width: auto;height: 32px;}
.product .group18 .btn .icon{width: 24px;}
.aipub.dev .group3 img,
.aipub.dev .group4 img,
.aipub.dev .group9 img,
.aipub.dev .group11 img{max-width: 558px;margin: 0 auto;}

.aipub{background-image: url('../images/product1-bg.png');}
.aipub .topArea{padding:272px 0 160px;}
.aipub .topArea .logo{width:138px;}
.aipub .topArea p b{@include fontStyle(40px, 700, $Black);}
.aipub .topArea .img{max-width: 824px;margin:120px auto 100px;}
.aipub .tabWrap{display: flex;margin-bottom: 160px;background: #F1F2F6;border-radius: 40px;}
.aipub .tabWrap button{flex:1;height: 80px;border-radius: 40px;@include fontStyle(24px, 700, #B4B4BE);transition: all 0.3s;}
.aipub .tabWrap button:hover{color:#333;}
.aipub .tabWrap button.active{background: $Black;color:#fff;}

.coaster{background-image: url('../images/product2-bg.png');}
.coaster .topArea{padding:272px 0 240px;}
.coaster .topArea .logo{width:212px;}
.coaster .group4{border-bottom:none;}

@media (max-width: $Tablet-width) {
  .product{background-size: 1212px auto;}
  .product .topArea{padding:192px 0 120px;}
  .product .topArea .logo{margin-bottom: 64px;}
  .product .topArea h2{font-size: 40px;line-height: 60px;}
  .product .topArea p{margin-top:24px;text-align: left;}
  .product .topArea p b{font-size: 24px;}
  .product .topArea .text br{display: none;}
  .product .topArea .img{max-width: 532px;margin:80px auto;}
  .product .tabWrap{margin-bottom: 120px;}
  .product iframe{max-width: 532px;}
  .product #product1 .flexBox img{margin: 0 auto;}
  .product section h3 br,
  .product section .text br{display: none;}
  .product .group1 img{margin-top:40px;}
  .product .group2{margin-top:120px}
  .product .group3,
  .product .group4{padding:120px 0;}
  .product .group5{padding-top:160px;background: none;}
  .product .group5 .pc{display: none;}
  .product .group5 img{z-index: 0;margin-top:36px;}
  .product #product2 .flexBox img{max-width: 676px;margin-left: auto;margin-right: auto;}
  .product .group9,
  .product .group12{padding: 120px 0;}
  .product .group13{padding:160px 0 0;background: none;}
  .product .group13 .pc{display: none;}
  .product .group13 img{z-index: 0;margin-top:36px;}
  .product .group15{text-align: left;}
  .product .group15 .container{flex-direction: column;gap:64px;}
  .product .group17 .imgWrap{padding:64px 32px;}
}
@media (max-width: $Mobile-width) {
  .product{background-size: 768px auto;}
  .product .topArea{padding:136px 0 80px;}
  .aipub .topArea .logo{width:103.5px;}
  .coaster .topArea .logo{width: 159px;}
  .product .topArea h2{font-size: 24px;line-height: 36px;}
  .product .topArea p b,
  .product .topArea p{font-size: 18px;line-height: 32px;}
  .product .topArea .img{margin:64px auto;}
  .product .tabWrap{margin-bottom: 80px;}
  .product .tabWrap button{height: 56px;font-size: 18px;}
  .product section .text{font-size: 16px;line-height: 28px;}
  .product section h2{margin-bottom: 48px;}
  .product section h2 img{height: 48px;}
  .product section h3 + p{margin-top:16px;}
  .product iframe{max-width: initial;margin-top:40px;}
  .product .group2{margin-top:80px}
  .product .group3{padding:80px 0;}
  .product.dev .group3{padding:80px 0 40px;}
  .product .group4{padding:80px 0;}
  .product .group5{padding:80px 0 0;text-align: left;}
  .product .group6{padding: 40px 0 0;text-align: left;}
  .product.ops .group6{padding-bottom: 80px;}
  .product .group9{padding: 80px 0 40px;}
  .product .group10{padding: 80px 0;}
  .product .group10 ul{gap:16px;}
  .product .group10 li{width:calc(50% - 16px);border-radius: 8px;padding: 16px;}
  .product .group11,
  .product .group12,
  .product .group13{padding:80px 0}
  .product .group15{padding:64px 0;margin-bottom: 80px;}
  .product .group15 .container{display: flex;align-items: center;justify-content: space-between;}
  .product .group15 h3{font-size: 24px;}
  .product .group15 .text{margin-top:16px;}
  .product .group15 .email{text-align: center;}
  .product .group15 img{max-width: 217px;}
  .product .group17{margin-top: 80px;}
  .product .group17 .imgWrap{padding:24px 16px;}
  .product .group18{padding:80px 0;}
  .product .group18 h3{font-size: 24px;line-height: 36px;}
  .product .group18 .btn{width:190px;height: 56px;margin-top: 40px;}
  .product .group18 .btn .logo{height: 24px;}
  .product .group18 .btn .icon{width: 18px;}
}

@media (max-width: $Fold-width) {
  .product .topArea h2 br{display: none;}
}


// recruit
.recruit .topArea{padding: 232px 0 125px;}
.recruit .topArea .container{display: flex;align-items: center;justify-content: space-between;gap: 40px;}
.recruit .topArea h2{@include fontStyle(72px, 800, $Black, 90px);}
.recruit .topArea p{margin-top:64px;@include fontStyle(24px, 700, $Black);}
.recruit .topArea img{max-width:498px;}
.recruit .section1{padding: 160px 0;background: #F7F8FB;}
.recruit .section1 .flexBox{align-items: stretch;}
.recruit section h2{@include fontStyle(48px, 700, $Black, 72px);}
.recruit .section1 .text1{margin-top:48px;@include fontStyle(32px, 300, #333, 48px);}
.recruit .section1 .text2{@include fontStyle(24px, 300, #333, 42px);}
.recruit .section1 .text2 + .text2{margin-top: 32px;}
.recruit .section1 .text2 b{font-weight: 700;}
.recruit .section1 .btn{@include btn(240px, 56px, 16px, 24px);}
.en .recruit .section1 .btn{width:fit-content;gap:24px;}
.recruit .section1 .btn img{width: 24px;}
.recruit .section1 .flexWrap{display: flex;flex-direction: column;justify-content: space-between;}
.recruit .section2{overflow: hidden;padding: 160px 0;border-bottom: 1px solid #D5D5DA;}
.recruit .section2 .step{margin: 80px -82px 80px 0;font-size: 0;}
.recruit .section2 .step li{position: relative;display: inline-block;width:20%;padding-right: 82px;box-sizing: border-box;vertical-align: top;}
.recruit .section2 .step li .num{position: absolute;left:2%;top:10%;width:30px;height: 30px;background: #333;border-radius: 50%;@include fontStyle(18px, 800, #fff, 32px);text-align: center;}
.recruit .section2 .step li .circle{display: flex;align-items: center;justify-content: center;width:100%;aspect-ratio: 1 / 1;border:1px dashed #878791;border-radius: 50%;}
.recruit .section2 .step li .circle p{@include fontStyle(24px, 500, #333);text-align: center;}
.recruit .section2 .step li .circle .sub{position: absolute;bottom: 23%;@include fontStyle(14px, 400, #878791);}
.recruit .section2 .step li:after{content:'';position: absolute;top:50%;right:30px;margin-top:-9px;@include icon(18px, 30px, 'recruit-arrow.png');}
.recruit .section2 .step li.last .num{border:2px solid #fff;}
.recruit .section2 .step li.last .circle{background: #333;border:none;}
.recruit .section2 .step li.last .circle p{color:#fff;}
.recruit .section2 .step li.last:after{display: none;}
.recruit .section2 .text{margin-top: 80px;@include fontStyle(24px, 400, #333, 42px);}
.recruit .section3{padding: 160px 0;}
.recruit .section3 .text{margin-top: 48px;@include fontStyle(32px, 300, #333, 48px);}
.recruit .section3 .box{display: flex;gap:92px;flex:none;}
.recruit .section3 .box img{width:auto;height: 120px;margin:0 auto;}
.recruit .section3 .box .text1{margin-top: 24px;@include fontStyle(24px, 700, #333);}
.recruit .section3 .box .text2{margin-top: 16px;@include fontStyle(16px, 400, #333, 24px);}

@media (max-width: $Tablet-width) {
  .recruit .topArea{padding: 192px 0 120px;}
  .recruit .topArea .container{align-items: flex-start}
  .recruit .topArea h2{font-size: 48px;line-height: 72px;}
  .recruit .topArea img{max-width:280px;}
  .recruit .section1{padding: 120px 0;}
  .recruit .section1 .text2 + .text2{margin-top: 24px;}
  .recruit .section1 .text2 b{font-weight: 700;}
  .recruit .section1 .btn{@include btn(240px, 56px, 16px, 24px);}
  .recruit .section1 .btn.tb-mb{display: flex !important;}
  .recruit .section1 .btn img{width: 24px;}
  .recruit .section2{padding: 120px 0;}
  .recruit .section2 .step{margin: 52px 0 -12px;text-align: center;}
  .recruit .section2 .step li{width:33.33%;padding:12px 66px 12px 0;}
  .recruit .section2 .step li .num{left:8%;}
  .recruit .section2 .step li:after{right:20px;}
  .recruit .section2 .text{margin-top: 64px;}
  .recruit .section3{padding: 120px 0;}
  .recruit .section3 .box{gap:0;justify-content: space-around;}
}

@media (max-width: $Mobile-width) {
  .recruit .topArea{padding: 116px 0 80px;}
  .recruit .topArea .container{flex-direction: column;align-items: center;}
  .recruit .topArea h2{margin-top: 40px;font-size: 32px;line-height: 48px;}
  .recruit .topArea p{margin-top:40px;font-size: 18px;}
  .recruit .topArea img{order:1;max-width:184px;}
  .recruit .topArea img.fade.in{transition-delay: 0s;}
  .recruit .topArea .content{order:2;width: 100%;}
  .recruit .section1{padding: 80px 0;}
  .recruit section h2{font-size: 32px;line-height: 48px;}
  .recruit .section1 .text1{margin-top:24px;font-size: 24px;line-height: 38px;}
  .recruit .section1 .text2{font-size: 18px;line-height: 32px;}
  .recruit .section1 .text2 + .text2{margin-top: 24px;}
  .recruit .section1 .btn,
  .en .recruit .section1 .btn{width:100%;}
  .recruit .section2{padding: 80px 0;}
  .recruit .section2 .step{margin: 32px 0 32px -16px;}
  .recruit .section2 .step li{width:50%;padding:8px 30px 8px 16px;}
  .recruit .section2 .step li .num{left:12%;width:26px;height: 26px;font-size: 16px;line-height: 26px;}
  .recruit .section2 .step li .circle p{font-size: 18px;}
  .recruit .section2 .step li .circle .sub{bottom: 23%;font-size: 12px;line-height: 14px;}
  .recruit .section2 .step li:after{right:0;width: 14px;height: 24px;margin-top:-12px;}
  .recruit .section2 .text{margin-top: 40px;font-size: 18px;line-height: 32px;}
  .recruit .section3{padding: 80px 0;}
  .recruit .section3.flexBox{gap:80px;}
  .recruit .section3 .text{margin-top: 24px;font-size: 24px;line-height: 36px;}
  .recruit .section3 .box{flex-direction: column;align-items: center;gap:56px;text-align: center;}
  .recruit .section3 .box br{display: none;}
  .recruit .section3 .box .text1{font-size: 18px;}
  .recruit .section3 .box .text2{margin-top: 8px;font-size: 14px;line-height: 21px;}
}

@media (max-width: $Fold-width) {
  .en .recruit .section1 .btn{padding:8px 24px;line-height: 20px;}
}

.inquiry .topArea{display: flex;justify-content: space-between;align-items: center;padding: 212px 0 105px;}
.inquiry .topArea h2{@include fontStyle(72px, 300, $Black, 90px);}
.inquiry .topArea h2 b{font-weight: 800;}
.inquiry .topArea h2 span{color: $Red;}
.inquiry .topArea img{width:322px;}
.inquiry .topArea .text1{margin-top: 64px;@include fontStyle(24px, 700, $Black);}
.inquiry .topArea .text2{margin-top: 24px;@include fontStyle(18px, 400, #333, 27px);}
.inquiry .formWrap{padding: 25px 0 320px;}
.inquiry .formWrap .message{margin-top: 16px;@include fontStyle(16px, 400, $Red);opacity: 0;}
.inquiry .formGroup.error .message,
.inquiry .formWrap .message.active{opacity: 1;}
.inquiry .formGroup.error .input{border-bottom-color: $Red;}
.inquiry .formGroup.error .textBox{border-color: $Red;}
.inquiry .formRow{display: flex;padding:43px 0;gap:32px;}
.inquiry .formRow > div{flex:1;}
.inquiry .formGroup label{display: block;margin-bottom: 24px;@include fontStyle(24px, 700, #333);}
.inquiry .formGroup label .require{color: $Red;}
.inquiry .formGroup input{width:100%;padding:0;@include fontStyle(18px, 400, #333, 56px);}
.inquiry .formGroup input:disabled{background: transparent;}
.inquiry .formGroup .input{border-bottom: 1px solid #D4D7DC;transition: all 0.2s ease;}
.inquiry .formGroup .input:focus{border-bottom-color: #333;}
.inquiry .formGroup.error .input:focus{border-bottom-color: $Red;}
.inquiry .formGroup .inputGroup{overflow: hidden;display: flex;align-items: center;gap:32px;}
.inquiry .formGroup .inputGroup > *{flex:1;}
.inquiry .formGroup .inputBox{display: flex;align-items: center;gap:8px;}
.inquiry .formGroup .inputBox span{flex: none;@include fontStyle(18px, 400, #B4B4BE);}
.inquiry .formGroup .dropWrap{position: relative;}
.inquiry .formGroup .dropWrap img{width: 24px;}
.inquiry .formGroup .dropWrap select{position: absolute;right: 0;top:0;width: 24px;height: 24px;opacity: 0;}
.inquiry .formGroup .dropdown{position: absolute;}
.inquiry .formGroup textarea{width:100%;height: 327px;padding: 0;resize: none;@include fontStyle(18px, 400, #333, 27px);}
.inquiry .formGroup .textBox{padding:24px;border: 1px solid #D4D7DC;}
.inquiry .formGroup .textBox p{margin-top: 8px;text-align: right;@include fontStyle(14px, 400, #333);}
.inquiry .formGroup .textBox p span{opacity: 0.4;}
.inquiry .formWrap .checkWrap{position: relative;}
.inquiry .formWrap .checkWrap input{position: absolute;left: 0;top:0;width:0;height: 0;opacity: 0;}
.inquiry .formWrap .checkWrap label{display: flex;align-items: center;gap:16px;@include fontStyle(18px, 400, #222);}
.inquiry .formWrap .checkWrap label b{font-weight: 700;text-decoration: underline;text-underline-offset:3px}
.inquiry .formWrap .checkWrap label .box{width:24px;height: 24px;border:1px solid #333;box-sizing: border-box;}
.inquiry .formWrap .checkWrap input:checked + label .box{border:none;@include icon(24px, 24px, 'icon-checkbox.png');}
.inquiry .formWrap .btn{@include btn(223px, 80px, 18px, 32px);margin:126px auto 0;}
.inquiry .formWrap .btn img{width: 24px;}
.inquiry .section{padding: 120px 0 160px;background: #eee url('../images/inquiry-bg.png') no-repeat center / auto 100%;}
.inquiry .section h2{@include fontStyle(18px, 700, #222);}
.inquiry .section p{margin-top: 64px;@include fontStyle(32px, 700, $Black, 48px);}
.inquiry .section .btnWrap{display: flex;gap:32px;margin-top: 80px;}
.inquiry .section .btnWrap a{flex:1;display: flex;align-items: center;justify-content: center;gap:24px;height: 80px;@include fontStyle(24px, 700, #333);background: #fff;box-shadow: 0px 4px 6px rgba(135, 135, 145, 0.2);border-radius: 8px;word-break: keep-all;}
.inquiry .section .btnWrap a img{width:32px;}
.inquiry .section .btnWrap .text{display: flex;align-items: center;gap:24px;}
.inquiry .section .btnWrap .text span{color: #7F7F7F;}

.popup{position: fixed;top:0;right: 0;bottom:0;left: 0;display: none;align-items: center;justify-content: center;z-index: 9;background: rgba(0,0,0,0.3);}
.popupWrap{display: flex;flex-direction: column;max-width: 640px;width: 100%;max-height: calc(100vh - 200px);background: #fff;}
.popupWrap .titleWrap{flex:none;display: flex;align-items: center;justify-content: space-between;height: 96px;padding-left:48px}
.popupWrap .titleWrap h2{@include fontStyle(24px, 700, $Black);}
.popupWrap .titleWrap .btnClose{width:96px;height: 96px;}
.popupWrap .titleWrap img{width: 32px;margin:0 auto;}
.popupWrap .popupContent{overflow: auto;flex:1;padding: 32px 48px;box-sizing: border-box;@include fontStyle(16px, 400, #333, 28px);}
.popupWrap .popupContent p + p{margin-top: 16px;}
.popupWrap .popupContent h3{margin: 40px 0 16px;@include fontStyle(16px, 800, $Black, 19px);}


@media (max-width: $Mobile-width) {
  .inquiry .topArea{flex-direction: column;padding: 116px 0 80px;}
  .inquiry .topArea img{order:1;width:118px;}
  .inquiry .topArea img.fade.in{transition-delay: 0s;}
  .inquiry .topArea .content{order:2;align-self: flex-start;margin-top: 40px;}
  .inquiry .topArea h2{font-size: 32px;line-height: 48px;}
  .inquiry .topArea .text1{margin-top: 40px;font-size: 18px;}
  .inquiry .topArea .text2{margin-top: 16px;font-size: 16px;line-height: 24px}
  .inquiry .formWrap{padding: 80px 0 160px;}
  .inquiry .formRow{flex-direction: column;padding:0;gap:0;}
  .inquiry .formRow + .formRow,
  .inquiry .formGroup + .formGroup{margin-top: 48px;}
  .inquiry .formGroup label{margin-bottom: 16px;font-size: 24px;}
  .inquiry .formGroup .inputGroup{flex-direction: column;align-items: flex-start;gap:0;}
  .inquiry .formGroup .inputBox{width: 100%;}
  .en .inquiry .formGroup input::placeholder{font-size: 12px;}
  .inquiry .formGroup textarea{height: 263px;}
  .inquiry .formGroup .textBox{padding:16px;}
  .inquiry .formWrap .checkWrap{margin-top: 16px;}
  .inquiry .formWrap .btn{width:100%;height: 56px;line-height: 56px;font-size: 16px;margin-top:80px;}
  .inquiry .section{padding: 80px 0;background-position: top right 30%;}
  .inquiry .section h2{font-size: 16px;}
  .inquiry .section p{margin-top: 24px;font-size: 18px;line-height: 32px;}
  .inquiry .section .btnWrap{flex-direction: column;gap:18px;margin-top: 40px;}
  .inquiry .section .btnWrap a{justify-content: space-between;gap:12px;height: 56px;padding: 0 24px;font-size: 16px;line-height: 56px;}
  .inquiry .section .btnWrap a img{width:18px;}
  .inquiry .section .btnWrap a .text{gap:12px;}
  .inquiry .section .btnWrap a .text span{width:56px;flex:none;}

  .popupWrap{max-width: initial;max-height:initial;height: 100%;}
  .popupWrap .titleWrap{height: 56px;padding-left:16px}
  .popupWrap .titleWrap h2{font-size: 18px;}
  .popupWrap .titleWrap .btnClose{width:56px;height: 56px;}
  .popupWrap .titleWrap img{width: 24px;}
  .popupWrap .popupContent{padding: 40px 16px;}
}

@media (max-width: $Fold-width) {
  .inquiry .section .btnWrap a{padding: 0 12px;gap:8px;}
  .inquiry .section .btnWrap a .text{gap:0;}
}