$Yello:#FFDB20;
$Red:#EF0000;
$Green: #3EB87D;
$Blue: #3F8ED6;
$Black: #1A1A1A;

$Tablet-width: 1024px;
$Mobile-width: 768px;
$Fold-width: 280px;

@mixin icon($width, $height, $filename, $display: inline-block) {
  display: $display;
  width: $width;
  height: $height;
  font-size: 0;
  background: url('../images/#{$filename}') no-repeat 0 0 / 100%;
}

@mixin fontStyle($size:inherit, $weight:inherit, $color:inherit, $height:inherit) {
  font-size: $size;
  color: $color;
  font-weight: $weight;
  line-height: $height;
}

@mixin btn($width, $height, $size, $padding:0) {
  width: $width;
  height: $height;
  line-height: $height;
  font-size: $size;
  padding: 0 $padding;
}