/* Slider */
/* line 2, src/assets/scss/slick.scss */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

/* line 21, src/assets/scss/slick.scss */
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

/* line 31, src/assets/scss/slick.scss */
.slick-list:focus {
  outline: none; }

/* line 35, src/assets/scss/slick.scss */
.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

/* line 41, src/assets/scss/slick.scss */
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* line 51, src/assets/scss/slick.scss */
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

/* line 59, src/assets/scss/slick.scss */
.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

/* line 66, src/assets/scss/slick.scss */
.slick-track:after {
  clear: both; }

/* line 70, src/assets/scss/slick.scss */
.slick-loading .slick-track {
  visibility: hidden; }

/* line 75, src/assets/scss/slick.scss */
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

/* line 83, src/assets/scss/slick.scss */
[dir='rtl'] .slick-slide {
  float: right; }

/* line 87, src/assets/scss/slick.scss */
.slick-slide img {
  display: block; }

/* line 91, src/assets/scss/slick.scss */
.slick-slide.slick-loading img {
  display: none; }

/* line 95, src/assets/scss/slick.scss */
.slick-slide.dragging img {
  pointer-events: none; }

/* line 99, src/assets/scss/slick.scss */
.slick-initialized .slick-slide {
  display: block; }

/* line 103, src/assets/scss/slick.scss */
.slick-loading .slick-slide {
  visibility: hidden; }

/* line 107, src/assets/scss/slick.scss */
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

/* line 115, src/assets/scss/slick.scss */
.slick-arrow.slick-hidden {
  display: none; }
